<template>
  <b-card class="bg-canjes">
    <div class="custom-container">
      <div class="d-flex flex-column align-items-center" style="gap: 2rem">
        <!-- mis coins -->
        <div
          class="bg-coins rounded d-flex flex-column justify-content-end"
          style="width: 100%; max-width: 400px; height: 450px; gap: 3rem"
        >
          <div class="mt-1 text-center">
            <b
              class="position-relative"
              style="font-size: 6rem; font-weight: 900"
            >
              <b v-if="totalCanjes > 0">
                <b-badge
                  variant="light"
                  class="text-white-50 position-absolute"
                  style="font-size: 1.3rem; top: 0; right: 0px"
                  >-{{ totalCanjes }}</b-badge
                ></b
              >
              {{ totalCoins }}
              <img
                style="width: 50px; margin-left: -30px"
                loading="lazy"
                src="/img/coins.png"
                alt="coins"
            /></b>
            <h3 class="" style="font-weight: 900">Mis Coins</h3>
          </div>

          <div class="row m-0 p-2">
            <vs-button
              size="xl"
              danger
              flat
              class="col-4 py-3 font-weight-bold"
              v-b-modal.history
            >
              <i class="fas fa-history mr-1"></i>
              Historial
            </vs-button>
            <vs-button
              size="xl"
              primary
              class="col py-3 font-weight-bold"
              to="/helex/market"
            >
              <i class="fas fa-store mr-1"></i>
              Canjear
            </vs-button>
          </div>
        </div>

        <h2 style="font-weight: 800">¿Qué quieres canjear hoy?</h2>
        <p class="text-center" style="font-size: 1.5rem">
          Explora las categorías de canjes que tenemos para ti
        </p>

        <!-- cards categorias -->
        <vs-card-group class="justify-content-center">
          <vs-card
            class="custom-card-canjes"
            type="3"
            v-for="cat in categorys"
            :key="cat.id"
          >
            <template #title>
              <h3 class="text-capitalize">
                {{ cat.title }}
              </h3>
            </template>
            <template #img>
              <img loading="lazy" :src="cat.image" :alt="cat.title" />
            </template>
            <template #text>
              <p class="">{{ cat.text }}</p>
            </template>
          </vs-card>
        </vs-card-group>

        <!-- <h2 style="font-weight: 600">Categoría y beneficios</h2>
        <p class="text-center" style="font-size: 1.5rem">
          Descubre cómo alcanzar tu siguiente categoría y conoce todos los
          beneficios
        </p> -->

        <!-- cards beneficios -->

        <!-- <div class="cards-beneficios mb-5">
          <b-card
            v-for="(ben, i) in beneficios"
            :key="i"
            class="card-ben bg-dark"
          >
            <div
              class="text-capitalize d-flex align-items-center justify-content-around position-absolute bg-info rounded h4 font-weight-bold"
              style="
                width: 140px;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
              "
            >
              {{ ben.title }}
              <vs-avatar circle size="45" danger>
                <i :class="`far fa-${ben.icon}`"></i>
              </vs-avatar>
            </div>
            <i
              :class="`far fa-${ben.icon} position-absolute text-white-50`"
              style="
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 7rem;
                opacity: 0.1;
              "
            ></i>
            <div
              class="d-flex flex-column align-items-center justify-content-center"
              style="gap: 0.5rem; height: 150px"
            >
              <span> ACUMULANDO </span>
              <strong class="h1" style="font-weight: 700">
                {{ ben.pts }}
                <img
                  style="width: 40px"
                  loading="lazy"
                  src="/img/coins.png"
                  alt="coins"
                />
              </strong>
            </div>
            <div class="d-flex flex-column" style="gap: 1rem">
              <div
                v-for="i in 3"
                :key="i"
                class="d-flex align-items-center"
                style="gap: 0.5rem"
              >
                <i class="fas fa-check-circle" style="font-size: 1.5rem"></i>
                <span class=""
                  >Lorem ipsum dolor sit amet, consectetur adipisicing.</span
                >
              </div>
            </div>
          </b-card>
        </div> -->

        <!--  gana coins -->

        <h2 style="font-weight: 600">¿Cómo ganar coins?</h2>
        <p
          class="text-center"
          style="font-size: 1.5rem; width: 100%; max-width: 900px"
        >
          Maximiza tu participación y desbloquea recompensas a medida que
          exploras las distintas formas de ganar coins que podrás canjear por
          emocionantes premios.
        </p>

        <div class="row" style="gap: 1rem; width: 100%; max-width: 600px">
          <!-- interaccion con la plataforma -->
          <div
            class="bg-dark rounded p-3 col-12 d-flex flex-column"
            style="gap: 1rem"
          >
            <div class="d-flex align-items-center" style="gap: 0.5rem">
              <vs-avatar circle size="70" primary>
                <img
                  loading="lazy"
                  src="/img/interaccion.png"
                  alt="interacciones"
                />
              </vs-avatar>
              <h3 class="h3 font-weight-bold">
                Interacciones con la plataforma
              </h3>
            </div>
            <!-- table interacciones -->
            <vs-table striped>
              <template #thead>
                <vs-tr>
                  <vs-th> Tipo </vs-th>
                  <vs-th style="width: 100px"> Coins </vs-th>
                  <vs-th> Estado </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <!-- avatar-->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold">Avatar</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.avatar }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <!-- completado / pendiente -->
                    <i
                      :class="`far fa-${
                        isAvatar ? 'circle-check' : 'circle-xmark'
                      } h3`"
                    ></i>
                  </vs-td>
                </vs-tr>
                <!-- onboarding -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold">Onboarding</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.onboarding }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <!-- completado / pendiente -->
                    <i
                      :class="`far fa-${
                        isOnboarding ? 'circle-check' : 'circle-xmark'
                      } h3`"
                    ></i>
                  </vs-td>
                </vs-tr>
                <!-- curriculum -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold">Curriculum</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.curriculum }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <!-- completado / pendiente -->
                    <i
                      :class="`far fa-${
                        isCurriculum ? 'circle-check' : 'circle-xmark'
                      } h3`"
                    ></i>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <!-- completa tareas -->
          <div
            class="bg-dark rounded p-3 col-12 d-flex flex-column"
            style="gap: 1rem"
          >
            <div class="d-flex align-items-center" style="gap: 0.5rem">
              <vs-avatar circle size="70" primary>
                <img loading="lazy" src="/img/tasks.png" alt="coins" />
              </vs-avatar>
              <h3 class="h3 font-weight-bold">Completa tareas</h3>
            </div>

            <div>
              <card class="pb-2" style="gap: 0.5rem">
                <b-badge> Nivel Básico </b-badge>
                <b class="h3 text-center">
                  <b class="h2">+</b>
                  <span style="font-size: 4rem; font-weight: 800">{{
                    configCoinsCanjes.tareaBasica
                  }}</span>
                  <img
                    style="width: 50px"
                    loading="lazy"
                    src="/img/coins.png"
                    alt="coins"
                  />
                </b>
                <!-- niveles de esfuerzo -->
                <div class="p-2 d-flex flex-column align-items-center">
                  <b class="">
                    <i class="fas fa-dumbbell"></i>
                    Niveles de esfuerzo:
                  </b>
                  <em class="font-weight-bold">1,2,3,5,7</em>
                </div>
                <div class="d-flex justify-content-center" style="gap: 1rem">
                  <!-- completas -->
                  <div
                    class="d-flex flex-column align-items-center border rounded p-3"
                  >
                    <h2>{{ tasksCoins.basico.completed }}</h2>
                    <b class="fw-bold">Completas</b>
                  </div>
                  <!-- pendientes -->
                  <div
                    class="d-flex flex-column align-items-center border rounded p-3"
                  >
                    <h2>{{ tasksCoins.basico.pendients }}</h2>
                    <b class="fw-bold">Pendientes</b>
                  </div>
                </div>
              </card>
              <card class="pb-2" style="gap: 0.5rem">
                <b-badge variant="warning"> Nivel Medio </b-badge>
                <b class="h3 text-center">
                  <b class="h2">+</b>
                  <span style="font-size: 4rem; font-weight: 800">{{
                    configCoinsCanjes.tareaMedia
                  }}</span>
                  <img
                    style="width: 50px"
                    loading="lazy"
                    src="/img/coins.png"
                    alt="coins"
                  />
                </b>
                <!-- niveles de esfuerzo -->
                <div class="p-2 d-flex flex-column align-items-center">
                  <b class="">
                    <i class="fas fa-dumbbell"></i>
                    Niveles de esfuerzo:
                  </b>
                  <em class="font-weight-bold">11,13,17,19,23,29,31</em>
                </div>
                <div class="d-flex justify-content-center" style="gap: 1rem">
                  <!-- completas -->
                  <div
                    class="d-flex flex-column align-items-center border rounded p-3"
                  >
                    <h2>{{ tasksCoins.medio.completed }}</h2>
                    <b class="fw-bold">Completas</b>
                  </div>
                  <!-- pendientes -->
                  <div
                    class="d-flex flex-column align-items-center border rounded p-3"
                  >
                    <h2>{{ tasksCoins.medio.pendients }}</h2>
                    <b class="fw-bold">Pendientes</b>
                  </div>
                </div>
              </card>
              <card class="pb-2" style="gap: 0.5rem">
                <b-badge variant="success"> Nivel Alto </b-badge>
                <b class="h3 text-center">
                  <b class="h2">+</b>
                  <span style="font-size: 4rem; font-weight: 800">{{
                    configCoinsCanjes.tareaAlta
                  }}</span>
                  <img
                    style="width: 50px"
                    loading="lazy"
                    src="/img/coins.png"
                    alt="coins"
                  />
                </b>
                <!-- niveles de esfuerzo -->
                <div class="p-2 d-flex flex-column align-items-center">
                  <b class="">
                    <i class="fas fa-dumbbell"></i>
                    Niveles de esfuerzo:
                  </b>
                  <em class="font-weight-bold">37,41,43,47</em>
                </div>
                <div class="d-flex justify-content-center" style="gap: 1rem">
                  <!-- completas -->
                  <div
                    class="d-flex flex-column align-items-center border rounded p-3"
                  >
                    <h2>{{ tasksCoins.alto.completed }}</h2>
                    <b class="fw-bold">Completas</b>
                  </div>
                  <!-- pendientes -->
                  <div
                    class="d-flex flex-column align-items-center border rounded p-3"
                  >
                    <h2>{{ tasksCoins.alto.pendients }}</h2>
                    <b class="fw-bold">Pendientes</b>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <!-- resultados evaluaciones -->
          <div
            class="bg-dark rounded p-3 col-12 d-flex flex-column"
            style="gap: 1rem"
          >
            <div class="d-flex align-items-center" style="gap: 0.5rem">
              <vs-avatar circle size="70" primary style="min-width: 70px">
                <img loading="lazy" src="/img/resulEvals.png" alt="coins" />
              </vs-avatar>
              <h3 class="h3 font-weight-bold">Resultados de evaluaciones</h3>
            </div>

            <!-- table resultados -->
            <vs-table striped>
              <template #thead>
                <vs-tr>
                  <vs-th> Rango de resultados </vs-th>
                  <vs-th style="width: 100px"> Coins </vs-th>
                  <vs-th> Ciclos evaluaciones </vs-th>
                  <vs-th> Total ganancias</vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <!-- 50 a 59% -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold"> 50 - 59%</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.rango_50_59 }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <b class="h3">
                      {{ rangoEvaluacionesCoins.r_50_59.cant }}
                    </b>
                  </vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      {{ rangoEvaluacionesCoins.r_50_59.ganTotal }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                </vs-tr>
                <!-- 60 a 74% -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold"> 60 - 74%</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.rango_60_74 }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <b class="h3"> {{ rangoEvaluacionesCoins.r_60_74.cant }}</b>
                  </vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      {{ rangoEvaluacionesCoins.r_60_74.ganTotal }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                </vs-tr>
                <!-- 75 a 84% -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold"> 75- 84%</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.rango_75_84 }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <b class="h3">{{ rangoEvaluacionesCoins.r_75_84.cant }}</b>
                  </vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      {{ rangoEvaluacionesCoins.r_75_84.ganTotal }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                </vs-tr>
                <!--  85 a 94% -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold"> 85- 94%</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.rango_85_94 }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <b class="h3"> {{ rangoEvaluacionesCoins.r_85_94.cant }}</b>
                  </vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      {{ rangoEvaluacionesCoins.r_85_94.ganTotal }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                </vs-tr>
                <!--  95%+ -->
                <vs-tr>
                  <vs-td> <b class="h3 font-weight-bold"> 95%+</b></vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      +{{ configCoinsCanjes.rango_95_100 }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                  <vs-td>
                    <b class="h3">
                      {{ rangoEvaluacionesCoins.r_95_100.cant }}</b
                    >
                  </vs-td>
                  <vs-td>
                    <b class="h3 font-weight-bold">
                      {{ rangoEvaluacionesCoins.r_95_100.ganTotal }}
                      <img
                        style="width: 20px"
                        loading="lazy"
                        src="/img/coins.png"
                        alt="coins" /></b
                  ></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </div>
    <!-- modal historial de canjes -->
    <b-modal id="history" title="Historial de canjes" size="lg" hide-footer>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col" class="rounded-left">#</th>
            <th scope="col">Beneficio</th>
            <th scope="col">Categoría</th>
            <th scope="col">Coins</th>
            <th scope="col" class="rounded-right">Fecha</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(canje, i) in listCanjes" :key="canje.id">
            <td scope="row" class="font-weight-bold">{{ i + 1 }}</td>
            <td>{{ canje.beneficio }}</td>
            <td>{{ canje.categoria }}</td>
            <td>
              {{ canje.coins }}
              <img
                style="width: 20px"
                loading="lazy"
                src="/img/coins.png"
                alt="coins"
              />
            </td>
            <td>{{ canje.fecha | timeSince }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </b-card>
</template>

<script>
import moment_timezone from "moment-timezone";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "canjes",
  data() {
    return {
      persona: {},
      listCanjes: [
        {
          id: null,
          beneficioId: null,
          beneficio: null,
          personaId: null,
          areaId: null,
          categoria: null,
          coins: null,
          fecha: null,
        },
      ],
      categorys: [
        {
          id: 1,
          title: "🍔snacks y alimentos",
          text: "Descuentos en restaurantes, comida rápiday antojos.",
          image:
            "https://images.unsplash.com/photo-1548940740-204726a19be3?q=80&w=1378&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 2,
          title: "🎉diversión y entretenimiento",
          text: "Descuentos en cine, entretención, suscripciones, servicios y mucho más.",
          image:
            "https://images.unsplash.com/photo-1563341591-a4ef278eb34b?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 3,
          title: "📚educación",
          text: "Descuentos en cursos, libros, herramientas educativas y más para potenciar tu aprendizaje.",
          image:
            "https://images.unsplash.com/photo-1513001900722-370f803f498d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 4,
          title: "🏅deporte y salud",
          text: "Ofertas en equipos deportivos, gimnasios, clases y todo lo necesario para mantener un estilo de vida activo.",
          image:
            "https://images.unsplash.com/photo-1507034589631-9433cc6bc453?q=80&w=1368&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 5,
          title: "🏖️tiempo libre",
          text: "Descuentos en actividades recreativas, viajes, eventos y todo lo que necesitas para disfrutar de tu tiempo libre.",
          image:
            "https://images.unsplash.com/photo-1603725948600-4be85317c7da?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          id: 6,
          title: "👔ropa y calzado",
          text: "Descubre increíbles descuentos en moda y calzado de última tendencia.",
          image:
            "https://images.unsplash.com/photo-1594883422096-c7f0b81e0133?q=80&w=1527&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
      ],
      beneficios: [
        {
          title: "fan",
          icon: "heart",
          pts: 1000,
        },
        {
          title: "premium",
          icon: "star",
          pts: 2500,
        },
        {
          title: "elite",
          icon: "gem",
          pts: 5000,
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listCanjes = [];
    await this.getData();
    this.$isLoading(false);
  },
  computed: {
    ...mapGetters({
      getIsAvatar: "getIsAvatar",
      getIsOnboarding: "getIsOnboarding",
      getIsCurriculum: "getIsCurriculum",
      getTotalCoins: "getTotalCoins",
      getTotalCanjes: "getTotalCanjes",
      getGananciasCoinsTasks: "getGananciasCoinsTasks",
      getGananciasCoinsRangoEvaluaciones: "getGananciasCoinsRangoEvaluaciones",
      getConfigCoinsCanjes: "getConfigCoinsCanjes",
    }),
    configCoinsCanjes() {
      return this.getConfigCoinsCanjes;
    },
    isAvatar() {
      return this.getIsAvatar;
    },
    isOnboarding() {
      return this.getIsOnboarding;
    },
    isCurriculum() {
      return this.getIsCurriculum;
    },
    totalCoins() {
      return this.getTotalCoins;
    },
    totalCanjes() {
      return this.getTotalCanjes;
    },
    tasksCoins() {
      return this.getGananciasCoinsTasks;
    },
    rangoEvaluacionesCoins() {
      return this.getGananciasCoinsRangoEvaluaciones;
    },
  },
  methods: {
    ...mapMutations(["setTotalCoins"]),
    updateTotalCoins(newValue) {
      this.setTotalCoins(newValue);
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CanjesMarketLog/ListByPersona/" + this.persona.id,
        });
        console.log("list canjes persona...", res);
        if (res.success) {
          this.listCanjes = res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>

<style scoped>
.cards-beneficios {
  display: flex;
  height: 420px;
  padding-top: 30px;
  overflow: auto;
  gap: 0.5rem;
  scroll-padding: 10px;
  scroll-snap-type: x mandatory;
}

.card-ben {
  flex: 0 0 350px;
  margin: 0 auto;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* .bg-canjes {
  background-image: url('/img/blob-scene-haikei-l.svg');

  background-size: cover;
  background-position: top;
}
.dark .bg-canjes {
  background-image: url('/img/blob-scene-haikei.svg');

  background-size: cover;
  background-position: top;
} */
</style>
